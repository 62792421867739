import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import misc from "./misc";
import userData from "./userData";

export default combineReducers({
  auth,
  message,
  misc,
  userData
});
