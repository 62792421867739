const initialState = {
  sidebarShow: 'responsive',
  user: "tutor"
}

export default function (state = initialState, { type, ...rest }){
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'setUser':
      return {...state, ...rest }
    default:
      return state
  }
}
