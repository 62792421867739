const initialState = {
  userData: false
}

export default function (state = initialState, { type, ...rest }){
  switch (type) {
    case 'setData':
      return {...state, ...rest }
    case 'clearData':
      return { userData: false }
    default:
      return state
  }
}
